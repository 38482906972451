import styled from "styled-components"
import { theme } from "./../styled/theme"

export const StyledInput: any = styled.input.attrs(props => {
	props.type === "text" || props.type === "number"
})`
	color: ${theme.colors.blue} !important;
	background: none !important;
	border: none;
	border-bottom: 1px solid ${theme.colors.blue};
	outline: none;
	width: 100%;
	font-size: 18px;
	font-weight: 400;

	&::placeholder {
		color: ${theme.colors.blue};
		font-style: italic;
	}

	${({ border }: any) =>
		border &&
		`
		border: 1px solid ${theme.colors.blue};
	`}

	${({ padding }: any) =>
		padding &&
		`
		padding: ${padding};
	`}

	${({ hidden }: any) =>
		hidden &&
		`
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	`}

	${({ checkbox }: any) =>
		checkbox &&
		`
		border: 1px solid #00157d;
		height: 30px;
		width: 30px;
		transition: background .3s ease-in-out;

		&[value="true"] {
			background: #00157d !important;
		}
	`}
`

export const StyledInputError: any = styled.div`
	position: absolute;
	bottom: -24px;
	color: #ff0716;
	font-size: 13px;
`

export const StyledInputGroup: any = styled.div`
	position: relative;
	margin-top: 50px;

	&:first-child {
		margin-top: 0;
	}
`
