import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { FieldArray, withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import slugify from "slugify"
import { AddOption } from "./AddOption"
import { FormLayout } from "./FormLayout"
import {
	IconSensory,
	IconPsychic,
	IconMystic,
	IconMeditation,
	IconHealth,
	IconGuidance,
	IconEnergy,
	IconBodywork,
	IconActivity,
} from "../icons"
import { StyledOption, StyledOptionWrapper, StyledInput, StyledText } from "../styled"
import { v4 as uuid } from "uuid"
import styled from "styled-components"

const components: any = {
	IconSensory,
	IconPsychic,
	IconMystic,
	IconMeditation,
	IconHealth,
	IconGuidance,
	IconEnergy,
	IconBodywork,
	IconActivity,
}

const StyledSubCategory: any = styled.div`
	@media (max-width: 1023px) {
		max-width: 400px;
		margin: 0 auto;
	}
	${({ selected }: any) =>
		!selected &&
		`
		display: none;
	`}
`

const StyledSubCategoryHeader: any = styled.div`
	display: flex;
		justify-content: space-between;
	}

	@media(min-width: 1024px) {
		position: fixed;
		top: 211px;
		width: 560px;
		left: 50%;
		pointer-events: none;
		z-index: 77777777777777;
		transform: translateX(-50%);
	}
`

const StyledSubCategoryHeaderImage: any = styled.div`
	height: 24px;
	width: 24px;
	margin-bottom: 4px;

	svg {
		height: 100%;
		width: 100%;
	}
`

const StyledSubCategoryHeaderContent: any = styled.div`
	text-align: right;

	h2 {
		margin: 0;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 400;
	}

	p {
		font-size: 11px;
		font-weight: 400;
	}
`

const Markup = ({
	handleSubmit,
	gotoPrevStep,
	gotoSubStep,
	categories,
	categoryCollection,
	values,
	handleChange,
	editPrevious,
}: any) => {
	const [current, setCurrent] = useState(1)
	const [localCategories, setLocalCategories] = useState(categoryCollection)
	const [currentValid, setCurrentValid] = useState(false)

	useEffect(() => {
		const hasSelected = values.categories[current - 1] && values.categories[current - 1].options.length > 0
		setCurrentValid(hasSelected)
	}, [current, values])

	const handlePrevStep = () => {
		if (current > 1) {
			setCurrent(current - 1)
			gotoSubStep(current - 1)
		} else {
			gotoPrevStep()
		}
	}

	const handleNextStep = () => {
		const hasSelected = values.categories[current - 1].options.length > 0
		if (!hasSelected) {
			return
		}
		if (current - 1 === categories.length) {
			handleSubmit()
		} else {
			setCurrent(current + 1)
			gotoSubStep(current + 1)
		}
	}

	const addToLocalCategory = (category: any, value: string) => {
		const updated = localCategories.map((localCat: any) => {
			if (category.id === localCat.id) {
				localCat.options.push({
					id: slugify(value, { lower: true }),
					label: value,
				})
				return localCat
			}
			return localCat
		})
		return setLocalCategories(updated)
	}

	const getLocalCategoryOptions = (index: number) =>
		localCategories.filter((cat: any) => cat.id === categories[index].id)[0].options

	const findCategoryOption = (index: number, categoryId: any) =>
		values.categories[index].options.filter((x: any) => x.id === categoryId)[0]

	const getCategoryIcon = (index: any) => localCategories.filter((cat: any) => cat.id === categories[index].id)[0].icon

	return (
		<FormLayout width={280} title="Now, choose all methods that you offer or add new ones." onSubmit={handleSubmit}>
			{categories.map((category: any, index: number) => (
				<StyledSubCategory key={index} selected={index === current - 1}>
					<FieldArray
						name={`categories[${index}].options`}
						render={arrayHelpers => (
							<div style={{ position: "relative" }}>
								<StyledSubCategoryHeader>
									<div>
										<StyledSubCategoryHeaderImage>
											{React.createElement(components[getCategoryIcon(index)])}
										</StyledSubCategoryHeaderImage>
										<StyledText small uppercase className="hidden--mobile">
											{category.label}
										</StyledText>
									</div>
									<StyledSubCategoryHeaderContent>
										<StyledText small uppercase className="hidden--desktop">
											{category.label}
										</StyledText>
										<p>
											{index + 1} / {categories.length}
										</p>
									</StyledSubCategoryHeaderContent>
								</StyledSubCategoryHeader>

								{getLocalCategoryOptions(index).map((categoryOption: any) => (
									<StyledOptionWrapper key={uuid()}>
										<StyledOption selected={!!findCategoryOption(index, categoryOption.id)} key={categoryOption.id}>
											<StyledInput
												hidden
												name={`categories[${index}].options`}
												type="checkbox"
												checked={!!findCategoryOption(index, categoryOption.id)}
												onChange={(event: any) => {
													if (event.target.checked) {
														arrayHelpers.push({
															id: categoryOption.id,
															label: categoryOption.label,
														})
													} else {
														const idx = values.categories[index].options.indexOf(
															findCategoryOption(index, categoryOption.id)
														)
														arrayHelpers.remove(idx)
													}
												}}
											/>
											<span>{categoryOption.label}</span>
										</StyledOption>
									</StyledOptionWrapper>
								))}
								<AddOption
									selector={category.id}
									handleChange={handleChange}
									values={values}
									onAdd={(value: string) => {
										addToLocalCategory(category, value)
										arrayHelpers.push({
											id: slugify(value, { lower: true }),
											label: value,
										})
									}}
								/>
							</div>
						)}
					/>
				</StyledSubCategory>
			))}
			<RegisterControls
				showPrev={!editPrevious}
				onPrevClick={handlePrevStep}
				onNextClick={handleNextStep}
				submit={current - 1 === categories.length}
				disabled={!currentValid}
			/>
		</FormLayout>
	)
}

const Form = withFormik({
	mapPropsToValues: (props: any) => {
		const makeInitialValues = props.categories.reduce((result: any, item: any) => {
			result[item.id] = ""
			return result
		}, {})
		return {
			categories: props.categories,
			...makeInitialValues,
		}
	},
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ categoryCollection, register, editPrevious }: any) => ({
	categories: register.categories,
	categoryCollection: categoryCollection,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 2 }),
		gotoSubStep: (subStep: number) => dispatch({ type: "SET_REGISTER_SUB_STEP", payload: subStep }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_THREE`, payload }),
	}
}

export const RegisterFormStep3 = connect(mapStateToProps, mapDispatchToProps)(Form)
