import { rgba } from "polished"
import React from "react"
import styled from "styled-components"
import { theme } from "../styled/theme"
import {
	IconSensory,
	IconPsychic,
	IconMystic,
	IconMeditation,
	IconHealth,
	IconGuidance,
	IconEnergy,
	IconBodywork,
	IconActivity,
} from "../icons"

const icons: any = {
	IconSensory,
	IconPsychic,
	IconMystic,
	IconMeditation,
	IconHealth,
	IconGuidance,
	IconEnergy,
	IconBodywork,
	IconActivity,
}

const StyledCategory: any = styled.div`
	padding: 16px;
	border-radius: 8px;
	transition: all 0.3s ease-in-out;
	max-width: 400px;
	margin: 0 auto;

	@media (max-width: 1023px) {
		display: flex;
		margin-bottom: 8px;
	}
	@media (min-width: 1024px) {
		&:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}

	${({ selected }: any) =>
		selected &&
		`
		background: #00157d;
		color: #fff;

		@media (min-width: 1024px) {
			&:hover {
				background: ${rgba("#00157d", 0.9)};
			}
		}
	`}
`

const StyledCategoryImage: any = styled.div`
	&,
	svg {
		height: 48px;
		width: 48px;
	}

	@media (max-width: 1023px) {
		margin-right: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media (min-width: 1024px) {
		margin-bottom: 12px;
	}
`
const StyledCategoryContent: any = styled.div`
	width: 100%;

	h2 {
		text-align: left;
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 6px;
		font-weight: 400;
		line-height: 1;
	}

	.description,
	.example {
		text-align: left;
		font-size: 11px;
		line-height: 1;
	}

	.description {
		margin-bottom: 6px;
	}

	.example {
		font-style: italic;
		opacity: 0.5;
		margin-bottom: 0;
	}
`

export const OptionCard = ({ title, description, example, onClick, isSelected, icon }: any) => {
	const Icon = icon
	return (
		<StyledCategory selected={isSelected} onClick={onClick}>
			{icon && (
				<StyledCategoryImage>
					{React.createElement(icons[Icon], {
						color: isSelected ? "#fff" : theme.colors.blue,
					})}
				</StyledCategoryImage>
			)}
			<StyledCategoryContent>
				<h2>{title}</h2>
				<p className="description">{description}</p>
				<p className="example">{example}</p>
			</StyledCategoryContent>
		</StyledCategory>
	)
}

export const StyledOptionCards = styled.div`
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 16px;
	}
`
