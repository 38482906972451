import { Link } from "@reach/router"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { Logo } from "./icons"
import { StyledContainer, StyledText } from "./styled"

const StyledFooter: any = styled.footer`
	background: #0d2031;
	display: flex;
	padding: 90px 0 36px;
	color: #fff;
	z-index: 500000;
	position: relative;

	@media (min-width: 1024px) {
		padding: 144px 0 124px;

		.description {
			max-width: 480px;
		}
	}

	ul {
		line-height: 143%;
		list-style: none;

		li {
			margin-bottom: 0;
		}

		.green {
			&,
			& a {
				color: #04aaaa;
			}

			& a {
				text-decoration: underline;
			}
		}

		.muted {
			opacity: 50%;
		}

		.dot {
			list-style: disc;
		}
	}

	.contact {
		font-size: 30px;
		line-height: 120%;
		text-transform: uppercase;
		color: #fff;
		margin-top: 64px;
		margin-bottom: 144px;

		a {
			color: #fff;
			border-bottom: 1px solid #fff;

			@media (min-width: 1024px) {
				&:hover {
					color: #04aaaa;
					border-color: #04aaaa;
				}
			}
		}

		@media (min-width: 1024px) {
			font-size: 60px;
			line-height: 144%;
		}
	}

	.text {
		line-height: 150%;
	}

	.hashtag {
		margin-bottom: 0;
	}

	.footer-layout {
		a {
			text-decoration: none;
		}

		@media (min-width: 1024px) {
			display: grid;
			grid-template-columns: 9fr 3fr;
			align-items: flex-end;

			.desktop-contact-menu {
				justify-self: flex-end;

				ul {
					line-height: 200%;
					margin-bottom: 0;
				}
			}
		}
	}
`

const ContactMenu = ({ className }: any) => (
	<ul className={`contact-menu ${className}`}>
		<li className="green dot">
			<StyledText small uppercase>
				<a href="mailto:support@raise-app.com">Contact</a>
			</StyledText>
		</li>
		{/* <li className="green dot">
			<StyledText small uppercase>
				Instagram
			</StyledText>
		</li>
		<li className="green dot">
			<StyledText small uppercase>
				Facebook
			</StyledText>
		</li>
		<li className="green dot">
			<StyledText small uppercase>
				Youtube
			</StyledText>
		</li>
		 */}
		<li className="green">
			<Link to="/imprint">
				<StyledText small uppercase>
					Imprint
				</StyledText>
			</Link>
		</li>
		<li className="green">
			<Link to="/legal">
				<StyledText small uppercase>
					Legal terms
				</StyledText>
			</Link>
		</li>
		<li className="muted">
			<StyledText small uppercase>
				Copyright {new Date().getFullYear()} Raise
			</StyledText>
		</li>
	</ul>
)

const Markup = ({}: any) => {
	return (
		<StyledFooter>
			<StyledContainer>
				<div className="footer-layout">
					<div>
						<StyledText landing className="description">
							We want to create synergies within the digital and physical realms and create a collective that will pave
							the way for a renaissance of introspection, healing and personal unfoldment.
						</StyledText>
						<p className="contact">
							Any more <br className="hidden--desktop" />
							questions?
							<br />
							<a href="mailto:info@raise-app.com">Contact us</a>
						</p>
						<ContactMenu className="hidden--desktop" />
						<p className="hashtag">#raiseyourconsciousness</p>
					</div>
					<div className="desktop-contact-menu">
						<ContactMenu className="hidden--mobile" />
					</div>
				</div>
			</StyledContainer>
		</StyledFooter>
	)
}

const mapStateToProps = ({}: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Markup)
