import React from "react"

import { connect } from "react-redux"
import { withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import { InputGroup } from "./InputGroup"
import { FormLayout } from "./FormLayout"
import { StyledText } from "../styled"
import styled from "styled-components"
import { hasValues } from "../../util/functions"

const StyledExpertisesStep: any = styled.div`
	@media (max-width: 1023px) {
		max-width: 400px;
		margin: 0 auto;
	}
`

const Markup = ({
	handleSubmit,
	gotoPrevStep,
	handleChange,
	handleBlur,
	values,
	errors,
	touched,
	isValid,
	editPrevious,
}: any) => (
	<StyledExpertisesStep>
		<FormLayout width={328} title="Add contact information." onSubmit={handleSubmit}>
			<InputGroup
				name="email"
				label="Email"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.email}
				error={errors.email}
				touched={touched.email}
				required={true}
			/>
			<StyledText tiny uppercase right bold marginTop="80px">
				Optional
			</StyledText>
			<InputGroup
				name="website"
				label="Website"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.website}
				error={errors.website}
				touched={touched.website}
			/>
			<InputGroup
				name="instagram"
				label="Instagram"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.instagram}
				error={errors.instagram}
				touched={touched.instagram}
			/>
			<InputGroup
				name="facebook"
				label="Facebook"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.facebook}
				error={errors.facebook}
				touched={touched.facebook}
			/>
			<InputGroup
				name="youtube"
				label="Youtube"
				handleChange={handleChange}
				handleBlur={handleBlur}
				value={values.youtube}
				error={errors.youtube}
				touched={touched.youtube}
			/>
			<RegisterControls
				showPrev={!editPrevious}
				disabled={!(hasValues(values) && isValid)}
				onPrevClick={gotoPrevStep}
			/>
		</FormLayout>
	</StyledExpertisesStep>
)

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		email: props.email,
		website: props.website,
		instagram: props.instagram,
		facebook: props.facebook,
		youtube: props.youtube,
	}),
	validate(values) {
		const errors: any = {}

		if (!values.email) {
			errors.email = "You must provide your email"
		}
		return errors
	},
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ register, editPrevious }: any) => ({
	email: register.email,
	website: register.website,
	instagram: register.instagram,
	facebook: register.facebook,
	youtube: register.youtube,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 5 }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_SIX`, payload }),
	}
}

export const RegisterFormStep6 = connect(mapStateToProps, mapDispatchToProps)(Form)
