import React from "react"

import { connect } from "react-redux"
import { withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import { FormLayout } from "./FormLayout"
import { StyledInput, StyledText, StyledTextarea } from "../styled"
import styled from "styled-components"

const StyledGridRow: any = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 3fr;
	grid-gap: 16px;
	margin-bottom: 12px;
	align-items: flex-end;
`
const StyledExpertisesStep: any = styled.div`
	@media (max-width: 1023px) {
		max-width: 400px;
		margin: 0 auto;
	}
`
const Markup = ({ handleSubmit, gotoPrevStep, handleChange, handleBlur, values, editPrevious }: any) => (
	<StyledExpertisesStep>
		<FormLayout width={328} title="Give us an idea about your offering and pricing." onSubmit={handleSubmit}>
			<StyledGridRow>
				<span></span>
				<StyledText tiny bold uppercase>
					Online
				</StyledText>
				<StyledText tiny bold uppercase>
					Offline
				</StyledText>
				<StyledText tiny bold uppercase>
					Price range
				</StyledText>
			</StyledGridRow>
			<StyledGridRow>
				<StyledText small>Sessions</StyledText>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.sessions.online"
					value={values.services.sessions.online}
				/>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.sessions.offline"
					value={values.services.sessions.offline}
				/>
				<StyledInput
					border
					padding="6px"
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="€"
					type="text"
					name="services.sessions.range"
					value={values.services.sessions.range}
				/>
			</StyledGridRow>
			<StyledGridRow>
				<StyledText small>Courses</StyledText>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.courses.online"
					value={values.services.courses.online}
				/>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.courses.offline"
					value={values.services.courses.offline}
				/>
				<StyledInput
					border
					padding="6px"
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="€"
					type="text"
					name="services.courses.range"
					value={values.services.courses.range}
				/>
			</StyledGridRow>
			<StyledGridRow>
				<StyledText small>Seminars</StyledText>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.seminars.online"
					value={values.services.seminars.online}
				/>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.seminars.offline"
					value={values.services.seminars.offline}
				/>
				<StyledInput
					border
					padding="6px"
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="€"
					type="text"
					name="services.seminars.range"
					value={values.services.seminars.range}
				/>
			</StyledGridRow>
			<StyledGridRow>
				<StyledText small>Events</StyledText>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.events.online"
					value={values.services.events.online}
				/>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.events.offline"
					value={values.services.events.offline}
				/>
				<StyledInput
					border
					padding="6px"
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="€"
					type="text"
					name="services.events.range"
					value={values.services.events.range}
				/>
			</StyledGridRow>
			<StyledGridRow>
				<StyledText small>Retreats</StyledText>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.retreats.online"
					value={values.services.retreats.online}
				/>
				<StyledInput
					checkbox
					onChange={handleChange}
					onBlur={handleBlur}
					type="checkbox"
					name="services.retreats.offline"
					value={values.services.retreats.offline}
				/>
				<StyledInput
					border
					padding="6px"
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="€"
					type="text"
					name="services.retreats.range"
					value={values.services.retreats.range}
				/>
			</StyledGridRow>
			<StyledText marginTop="48px" marginBottom="4px" center>
				Anything else?
			</StyledText>
			<StyledText small center italic marginBottom="24px">
				Special offers, package deals, trial sessions...
			</StyledText>
			<StyledTextarea
				value={values.services.specialOffers}
				onChange={handleChange}
				onBlur={handleBlur}
				name="services.specialOffers"
				placeholder="Please describe..."
			></StyledTextarea>
			<RegisterControls showPrev={!editPrevious} onPrevClick={gotoPrevStep} />
		</FormLayout>
	</StyledExpertisesStep>
)

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		services: props.services,
	}),
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ register, editPrevious }: any) => ({
	services: register.services,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 6 }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_SEVEN`, payload }),
	}
}

export const RegisterFormStep7 = connect(mapStateToProps, mapDispatchToProps)(Form)
