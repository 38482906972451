import styled from "styled-components"
import { theme } from "./../styled/theme"

export const StyledLink: any = styled.label`
	color: ${theme.colors.blue};
	text-decoration: none;
	font-weight: 700;

	@media (min-width: 1024px) {
		&:hover {
			border-bottom: 1px solid ${theme.colors.blue};
		}
	}
`
