import React from "react"

import { connect } from "react-redux"
import styled from "styled-components"
import { FormLayout } from "."

import { StyledButton, StyledControls, StyledH2, StyledText } from "../styled"
import OpenerImage from "./../images/OpenerImage"

const StyledJoinPage: any = styled.div`
	margin-top: 100px;
	padding-bottom: 150px;

	img {
		margin-bottom: 24px;
	}

	.layout {
		margin-bottom: 30px;
	}

	.left {
		grid-row: 1;
		grid-column: 1;
	}
	.right {
		margin-bottom: 36px;
		grid-row: 1;
		grid-column: 2;
	}

	.left ${StyledText} {
		margin-bottom: 24px;
	}

	@media (min-width: 1024px) {
		margin-top: 200px;
		.layout {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
			margin-bottom: 70px;
		}

		${StyledH2} {
			font-size: 50px;
		}
	}
	.start-control {
		padding-top: 92px;
	}

	${StyledControls} {
		bottom: -200px;
		transition: bottom 0.3s ease-in-out;
		${({ showRegisterForm }: any) =>
			showRegisterForm &&
			`
			bottom: 0; 
		`}
	}
`

const Markup = ({ start, showRegisterForm }: any) => {
	return (
		<StyledJoinPage showRegisterForm={showRegisterForm}>
			<FormLayout header={false}>
				<div className="layout">
					<div className="right">
						<div>
							<OpenerImage fileName={"registration.png"} />
						</div>
					</div>
					<div className="left">
						<StyledH2>Help us to build a new collective</StyledH2>
						<StyledText landing>
							We designed this registration to build an innovative system for matching you with potential customer
							needs.
						</StyledText>
					</div>
				</div>
				{
					<StyledControls>
						<div className="start-control">
							<StyledButton dark centered onClick={start}>
								Start
							</StyledButton>
						</div>
					</StyledControls>
				}
			</FormLayout>
		</StyledJoinPage>
	)
}
const mapStateToProps = ({ register }: any) => ({ register })

const mapDispatchToProps = (dispatch: any) => {
	return {
		start: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 1 }),
	}
}

export const RegisterFormStep0 = connect(mapStateToProps, mapDispatchToProps)(Markup)
