import React from "react"

export const PersonalGuidance = ({ color = "#00157D" }: any) => {
	return (
		<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.5002 43.8001C34.2639 43.8001 43.8002 34.2637 43.8002 22.5001C43.8002 10.7364 34.2639 1.20007 22.5002 1.20007C10.7365 1.20007 1.2002 10.7364 1.2002 22.5001C1.2002 34.2637 10.7365 43.8001 22.5002 43.8001Z"
				stroke={color}
				strokeWidth="0.75"
				strokeMiterlimit="10"
			/>
			<path
				d="M31.8002 22.5002C31.8002 27.6002 27.6002 31.8002 22.5002 31.8002C17.4002 31.8002 13.2002 27.6002 13.2002 22.5002H22.5002V13.2002C27.6002 13.2002 31.8002 17.4002 31.8002 22.5002Z"
				fill={color}
			/>
		</svg>
	)
}
