import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { Close, Logo } from "./icons"
import { LogoIcon } from "./icons/LogoIcon"
import { StyledContainer } from "./styled"
import { theme } from "./styled/theme"

const StyledHeaderWrapper: any = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100000000;
`

const StyledHeader: any = styled.div`
	padding: 29px 16px;

	@media (min-width: 1024px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 64px 16px;
	}
`

const StyledAppLogo: any = styled.div`
	width: 100%;
	display: flex;
	padding 0;
	@media (max-width: 1023px) {
		justify-content: center;
	}
`

const StyledAppMenuMobile: any = styled.ul`
	@media (min-width: 1024px) {
		display: none;
	}

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5000000;

	background: rgba(0, 0, 0, 0.3);

	font-size: 30px;
	line-height: 120%;
	text-transform: uppercase;
	font-variant: small-caps;
	list-style: none;
	margin: 0;
	transition: opacity 0.3s ease-in-out;

	${({ open }: any) =>
		!!!open &&
		`
		pointer-events: none;
		opacity: 0;
	`}

	li {
		color: ${theme.colors.blue};
	}

	.menu-wrapper {
		width: 80%;
		background: #ecebee;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 50px;

		transition: transform 0.3s ease-in-out;
		transform: translateX(${({ open }: any) => (!!open ? `0` : `100%`)});
	}
`
const StyledAppMenuDesktop: any = styled.ul`
	font-size: 13px;
	text-transform: uppercase;
	font-variant: small-caps;
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	@media (max-width: 1023px) {
		display: none;
	}

	li {
		padding: 0;
		margin: 0;
		margin-left: 16px;

		color: ${({ color }: any) => color || "#00157d"};
		padding-bottom: 4px;
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		@media (min-width: 1024px) {
			&:hover {
				color: #04aaaa;
				border-color: #04aaaa;
			}
		}

		&.active {
			border-color: ${({ color }: any) => color || "#00157d"};

			@media (min-width: 1024px) {
				&:hover {
					border-color: #04aaaa;
				}
			}
		}
	}
`
const StyledAppMenuToggle: any = styled.div`
	position: fixed;
	top: 16px;
	right: 16px;
	height: 40px;
	width: 40px;
	z-index: 100000000;
	transition: transform 0.2s ease-in-out;
	transform: rotateZ(${({ open }: any) => (open === true ? `0` : `-45deg`)});
	@media (min-width: 1024px) {
		display: none;
	}

	svg {
		height: 40px;
		width: 40px;
	}
	${({ open }: any) =>
		!!open &&
		`
		path {
			fill: ${theme.colors.blue};
		}
	`}
`

const Markup = ({ onClick, showMobileMenu, setShowMobileMenu, headerColor }: any) => {
	const toggleShowMobileMenu = () => setShowMobileMenu(!showMobileMenu)
	const handleMenuClick = (id: string, showMenu: boolean = false) => {
		if (showMenu === false || showMenu === true) {
			setShowMobileMenu(showMenu)
		}
		onClick && onClick(id)
	}
	return (
		<StyledHeaderWrapper>
			<StyledContainer width={1280}>
				<StyledHeader>
					<Link to="/">
						<StyledAppLogo onClick={() => handleMenuClick("home", false)}>
							<Logo color={headerColor} />
						</StyledAppLogo>
					</Link>
					<StyledAppMenuToggle open={showMobileMenu} onClick={toggleShowMobileMenu}>
						<Close color={headerColor} />
					</StyledAppMenuToggle>
					<StyledAppMenuMobile open={showMobileMenu} onClick={toggleShowMobileMenu}>
						<div className="menu-wrapper" onClick={e => e.stopPropagation()}>
							<li onClick={() => handleMenuClick("explore", false)} className="header__menu-item">
								{/* <Link activeClassName="active" to="/explore"> */}
								Explore
								{/* </Link> */}
							</li>
							<li onClick={() => handleMenuClick("join", false)} className="header__menu-item">
								{/* <Link activeClassName="active" to="/join-collective"> */}
								Join
								{/* </Link> */}
							</li>
							<li onClick={() => handleMenuClick("vision", false)} className="header__menu-item">
								{/* <Link activeClassName="active" to="/share-vision"> */}
								Vision
								{/* </Link> */}
							</li>
							<li onClick={() => handleMenuClick("contact", false)} className="header__menu-item">
								{/* <Link activeClassName="active" to="/contact"> */}
								Contact
								{/* </Link> */}
							</li>
						</div>
					</StyledAppMenuMobile>
					<StyledAppMenuDesktop color={headerColor}>
						<li onClick={() => handleMenuClick("explore")} className="header__menu-item">
							{/* <Link activeClassName="active" to="/explore"> */}
							Explore
							{/* </Link> */}
						</li>
						<li onClick={() => handleMenuClick("join")} className="header__menu-item">
							{/* <Link activeClassName="active" to="/join-collective"> */}
							Join
							{/* </Link> */}
						</li>
						<li onClick={() => handleMenuClick("vision")} className="header__menu-item">
							{/* <Link activeClassName="active" to="/share-vision"> */}
							Vision
							{/* </Link> */}
						</li>
					</StyledAppMenuDesktop>
				</StyledHeader>
			</StyledContainer>
		</StyledHeaderWrapper>
	)
}

const mapStateToProps = ({ showMobileMenu, headerColor }: any) => ({ showMobileMenu, headerColor })

const mapDispatchToProps = (dispatch: any) => ({
	setShowMobileMenu: (payload: boolean) => dispatch({ type: `SHOW_MOBILE_MENU`, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Markup)
