import React, { Fragment, useEffect, useState } from "react"

import { connect } from "react-redux"
import styled from "styled-components"
import { Illustration, Logo } from "../icons"
import { StyledButton, StyledLink, StyledText } from "../styled"
import { FormLayout } from "./FormLayout"
import { RegisterControls } from "./RegisterControls"

const StyledContent: any = styled.div`
	position: relative;
	margin-top: 66px;
	padding: 0 40px 150px;
	overflow: hidden;
	height: 100%;
	width: 100%;
	max-width: 566px;
	margin-right: auto;
	margin-left: auto;
`
const StyledContentLogo: any = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;

	svg {
		width: 110px;
		height: 25px;
	}
`
const StyledContentTitle: any = styled.h1`
	font-size: 25px;
	font-weight: 700;
	margin-top: 96px;
	line-height: 120%;
`

const StyledIllustration: any = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: 1001;

	svg {
		height: 100%;
		position: relative;
		left: 50%;
		transform: translateX(-50%) scale(1.25);
	}
`

const StyledLoading: any = styled.div`
	text-align: center;
	margin-top: 240px;
`

const StyledExpertisesStep: any = styled.div`
	@media (max-width: 1023px) {
		max-width: 400px;
		margin: 0 auto;
	}
`

const Markup = ({ register, submit }: any) => {
	const [loading, setLoading] = useState(true)
	const [success, setSuccess] = useState(false)
	const postFormData = () => {
		fetch(`${process.env.API_ENDPOINT}/`, {
			method: "post",
			body: JSON.stringify(register),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(response => {
				if (response.status === 200) {
					console.log("success")
					setLoading(false)
					setSuccess(true)
				} else {
					console.log("wrong response code")
					setLoading(false)
					setSuccess(false)
				}
			})
			.catch(() => {
				console.log("error")
				setLoading(false)
				setSuccess(false)
			})
	}
	useEffect(() => {
		postFormData()
	}, [])
	return (
		<StyledExpertisesStep>
			<FormLayout header={false}>
				<StyledContent>
					<StyledContentLogo>
						<Logo />
					</StyledContentLogo>
					{loading && <StyledLoading>Loading...</StyledLoading>}
					{!loading && success && (
						<Fragment>
							<StyledContentTitle>
								Thank You!!! <br />
								Your application is complete.
							</StyledContentTitle>
							<StyledText landing>
								We will get back to you as soon as we have reviewed the application. If you have any questions feel free
								to contact us anytime!
							</StyledText>
							<StyledLink href="mailto:support@raise-app.com">support@raise-app.com</StyledLink>
						</Fragment>
					)}
					{!loading && !success && (
						<Fragment>
							<StyledContentTitle>Sorry, something went wrong...</StyledContentTitle>
							<p>Please try again or contact us at</p>
							<p>
								<StyledLink href="mailto:support@raise-app.com">support@raise-app.com</StyledLink>
							</p>
							<p>
								<StyledButton onClick={() => postFormData()}>Try again</StyledButton>
							</p>
						</Fragment>
					)}
				</StyledContent>
				<StyledIllustration>
					<Illustration />
				</StyledIllustration>
				{!loading && <RegisterControls showPrev={false} nextLabel="Close" onNextClick={submit} />}
			</FormLayout>
		</StyledExpertisesStep>
	)
}
const mapStateToProps = ({ register }: any) => ({ register })

const mapDispatchToProps = (dispatch: any) => {
	return {
		submit: () => dispatch({ type: `SUBMIT_REGISTER_STEP_TEN` }),
	}
}

export const RegisterFormStep10 = connect(mapStateToProps, mapDispatchToProps)(Markup)
