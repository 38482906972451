import React from "react"

import { connect } from "react-redux"
import { withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import { FormLayout } from "./FormLayout"
import { StyledTextarea } from "../styled"
import styled from "styled-components"

const StyledExpertisesStep: any = styled.div`
	@media (max-width: 1023px) {
		max-width: 400px;
		margin: 0 auto;
	}
`

const Markup = ({ handleSubmit, gotoPrevStep, handleChange, handleBlur, values }: any) => (
	<StyledExpertisesStep>
		<FormLayout
			width={566}
			title="Do you have any feedback on how we can improve the application process?"
			onSubmit={handleSubmit}
		>
			<StyledTextarea
				minHeight="240px"
				value={values.feedback}
				onChange={handleChange}
				onBlur={handleBlur}
				name="feedback"
				placeholder="Leave comment..."
			></StyledTextarea>
			<RegisterControls onPrevClick={gotoPrevStep} nextLabel={values.feedback !== "" ? "Finish" : "Skip"} />
		</FormLayout>
	</StyledExpertisesStep>
)

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		feedback: props.feedback,
	}),
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ register }: any) => ({
	feedback: register.feedback,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 8 }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_NINE`, payload }),
	}
}

export const RegisterFormStep9 = connect(mapStateToProps, mapDispatchToProps)(Form)
