import React from "react"

export const IconActivity = ({ color = "#00157D" }: any) => {
	return (
		<svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M25.1086 33.8513L22.9551 21.5927" stroke={color} strokeWidth="1.4537" strokeMiterlimit="10" />
			<path
				d="M26.0312 20.178C26.9541 25.8359 27.877 31.4938 28.4923 35.1086C28.8 37.1517 27.7232 36.6802 27.7232 36.6802"
				stroke={color}
				strokeWidth="1.4537"
				strokeMiterlimit="10"
			/>
			<path
				d="M33.4139 16.7205C33.4139 16.8777 33.4139 17.0348 33.4139 17.0348C33.2601 18.6064 31.5681 19.8637 29.5685 19.8637C27.415 19.8637 25.723 18.4493 25.723 16.7205C25.723 14.9917 27.415 13.5773 29.5685 13.5773C30.6452 13.5773 31.5681 13.8916 32.1834 14.3631C32.7986 14.9917 33.4139 15.7775 33.4139 16.7205Z"
				stroke={color}
				strokeWidth="1.4537"
				strokeMiterlimit="10"
			/>
			<path
				d="M28.9533 39.6662C26.646 40.2948 24.954 38.5661 24.954 38.5661C24.954 38.5661 16.3403 30.8651 11.8795 26.9361C11.5719 26.6217 11.2643 26.3074 11.1105 26.1502C7.88029 22.3784 9.57228 18.9208 10.0337 17.8207C10.1876 17.6635 10.1876 17.5063 10.1876 17.5063C10.3414 17.3492 10.3414 17.192 10.4952 17.0348C12.6486 13.8916 16.0326 13.8916 19.109 13.8916C20.4933 13.8916 21.1086 13.1058 21.4162 12.7915L19.4166 1.16146C19.4166 1.16146 22.0315 0.218487 22.9544 2.89024C23.4159 4.14754 24.8002 12.1628 26.0308 20.1781C25.1079 21.1211 24.185 21.4354 22.9544 21.5925C21.4162 21.7497 19.7242 21.5925 17.7246 21.5925C14.1868 21.5925 16.8017 25.2073 16.8017 25.2073L27.7228 36.6801C27.7228 36.6801 30.4915 39.1947 28.9533 39.6662Z"
				stroke={color}
				strokeWidth="1.4537"
				strokeMiterlimit="10"
			/>
			<path
				d="M4.95765 36.2086C4.95765 36.2086 6.49583 38.566 6.18819 39.1947C5.88056 39.8233 2.80421 39.0375 1.57366 38.0945C0.496941 37.3087 1.26603 35.7371 1.26603 35.7371C1.26603 35.7371 6.95728 22.6925 10.1875 17.8204C9.57218 18.7634 7.88019 22.221 11.1104 25.9929L4.95765 36.2086Z"
				stroke={color}
				strokeWidth="1.4537"
				strokeMiterlimit="10"
			/>
		</svg>
	)
}
