import React from "react"

export const Close = ({ color = "#00157D" }: any) => {
	return (
		<svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.2279 13.4791L13.4792 38.2279L12.7721 37.5208L37.5208 12.772L38.2279 13.4791Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M37.5208 38.2281L12.772 13.4793L13.4791 12.7722L38.2279 37.521L37.5208 38.2281Z"
				fill={color}
			/>
		</svg>
	)
}
