import styled from "styled-components"
import { theme } from "./../styled/theme"

export const StyledTextarea: any = styled.textarea`
	color: ${theme.colors.blue} !important;
	background: none !important;
	border: 1px solid ${theme.colors.blue};
	outline: none;
	width: 100%;
	font-size: 18px;
	font-weight: 400;
	min-height: 120px;
	resize: vertical;
	padding: 16px;

	&::placeholder {
		color: ${theme.colors.blue};
		font-style: italic;
	}

	${({ minHeight }: any) =>
		minHeight &&
		`
		min-height: ${minHeight};
	`}
`
