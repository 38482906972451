import { linearGradient, rgba } from "polished"
import React, { Fragment } from "react"
import styled from "styled-components"

const StyledFormHeader: any = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 15px;
	padding: 0 24px;
	z-index: 980000;
	background: ${linearGradient({
		colorStops: [`${rgba("#ecebef", 1)} 10%`, `${rgba("#ecebef", 1)} 70%`, `${rgba("#ecebef", 0)} 95%`],
		toDirection: "180deg",
		fallback: "#ecebef",
	})}
	height: 180px;
	pointer-events: none;

	@media(min-width: 1024px) {
		height: 220px;
	}
`
const StyledFormTitle: any = styled.h1`
	font-size: 18px;
	line-height: 130%;
	font-weight: 400;
	color: #00157d;
	text-align: center;
	margin-top: 78px;

	@media (min-width: 1024px) {
		margin: 0 auto;
		margin-top: 126px;
		max-width: 380px;
	}
`

const StyledForm: any = styled.form`
	padding-top: 170px;
	padding-bottom: 160px;
	margin: 0;

	@media (min-width: 1024px) {
		padding-top: 213px;
		margin: 0 auto;
		${({ width }: any) =>
			width &&
			`
			max-width: ${width}px;
		`}
	}
`

export const FormLayout = ({ title = "", onSubmit, children, header = true, width }: any) => {
	return (
		<Fragment>
			{header && (
				<StyledFormHeader>
					<StyledFormTitle>{title}</StyledFormTitle>
				</StyledFormHeader>
			)}
			{onSubmit ? (
				<StyledForm width={width} onSubmit={onSubmit}>
					{children}
				</StyledForm>
			) : (
				<Fragment>{children}</Fragment>
			)}
		</Fragment>
	)
}
