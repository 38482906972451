import React from "react"

export const IconSensory = ({ color = "#00157D" }: any) => {
	return (
		<svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.83554 40.209C5.10289 40.209 4.22283 38.6437 3.94971 37.2063C3.88901 36.8868 3.85867 36.4396 3.82832 35.8646C3.70693 33.948 3.4945 30.7536 1.58266 29.6676C0.672252 29.1565 0.156356 28.5495 0.0349686 27.8787C-0.177459 26.7926 0.611558 25.8343 1.49162 24.7163C2.1289 23.9177 2.82687 23.0552 3.22138 22.065C3.79797 20.4039 3.64624 18.9345 3.4945 17.3692C3.25173 15.1651 2.97861 12.6416 4.79942 9.15971C8.95693 0.247418 19.1535 0.151587 19.5783 0.151587C24.8587 -0.51923 29.1982 1.04601 32.0508 4.65565C35.9656 9.57498 36.4511 17.529 34.9035 22.7997L33.7199 22.4163C35.1766 17.4651 34.7214 10.0541 31.1101 5.48618C28.5003 2.19598 24.6159 0.822404 19.639 1.42933C19.4569 1.42933 9.7156 1.55711 5.86156 9.73469C4.22283 12.8971 4.4656 15.0693 4.67803 17.2095C4.86011 18.8386 5.04219 20.5636 4.34422 22.5122C3.88901 23.6941 3.1 24.6843 2.40202 25.5468C1.76474 26.3454 1.12745 27.144 1.21849 27.6232C1.27919 27.9745 1.76474 28.294 2.1289 28.5176C4.64768 29.955 4.86011 33.5647 5.01185 35.7369C5.04219 36.248 5.07254 36.6313 5.13323 36.9188C5.31531 37.7813 5.64913 39.5701 11.6881 38.6437L11.8702 39.9215C10.6867 40.1131 9.68525 40.209 8.83554 40.209Z"
				fill={color}
			/>
			<path d="M17.333 11.0763L19.6697 15.3887H19.7001V15.3567L17.333 11.0763Z" fill={color} />
			<path d="M20.7625 15.9951L18.9417 19.3173L20.7625 15.9951Z" fill={color} />
			<path
				d="M29.2581 10.4051L26.193 4.81494H20.0326L17.3014 9.76621H11.8997L8.83466 15.3564L11.8997 20.9465H17.3014L20.0326 25.8978H26.1627L29.2277 20.3076L26.5268 15.3564L29.2581 10.4051ZM20.761 6.12463H25.4647L27.8014 10.4051L25.4647 14.7175H20.761L18.3939 10.4051L20.761 6.12463ZM12.628 19.6688L10.261 15.3564L12.628 11.0759H17.3318L19.6988 15.3564V15.3883H19.6685L17.3318 19.6688H12.628ZM27.8318 20.3076L25.4951 24.5881H20.761L18.3939 20.3076L18.9401 19.3174L20.761 15.9952H25.4951L27.8318 20.3076Z"
				fill={color}
			/>
		</svg>
	)
}
