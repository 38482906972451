import React from "react"

export const Web = ({ color = "#00157D" }: any) => {
	return (
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
			<g>
				<g>
					<path
						d="M437.019,74.981C388.667,26.628,324.38,0,256,0C187.62,0,123.332,26.628,74.981,74.98C26.628,123.332,0,187.62,0,256
					s26.628,132.667,74.981,181.019C123.332,485.371,187.62,511.999,256,511.999c68.381,0,132.667-26.628,181.02-74.981
					c48.351-48.351,74.98-112.639,74.98-181.019S485.371,123.332,437.019,74.981z M96.216,96.216
					c22.511-22.511,48.938-39.681,77.742-50.888c-7.672,9.578-14.851,20.587-21.43,32.969c-7.641,14.38-14.234,30.173-19.725,47.042
					c-19.022-3.157-36.647-7.039-52.393-11.595C85.345,107.678,90.61,101.822,96.216,96.216z M62.229,139.585
					c18.417,5.897,39.479,10.87,62.461,14.809c-6.4,27.166-10.167,56.399-11.066,86.591H30.536
					C32.896,204.752,43.778,170.172,62.229,139.585z M60.594,369.638c-17.455-29.899-27.769-63.481-30.059-98.623h83.146
					c0.982,29.329,4.674,57.731,10.858,84.186C101.085,359.003,79.494,363.85,60.594,369.638z M96.216,415.784
					c-6.38-6.381-12.322-13.081-17.831-20.055c16.323-4.526,34.571-8.359,54.214-11.433c5.53,17.103,12.194,33.105,19.928,47.662
					c7.17,13.493,15.053,25.349,23.51,35.505C146.427,456.28,119.268,438.834,96.216,415.784z M240.984,478.115
					c-22.808-6.389-44.384-27.217-61.936-60.249c-6.139-11.552-11.531-24.155-16.15-37.587c24.73-2.722,51.045-4.331,78.086-4.709
					V478.115z M240.984,345.537c-29.988,0.409-59.217,2.292-86.59,5.507c-6.038-24.961-9.671-51.978-10.668-80.028h97.259V345.537z
					M240.984,240.984h-97.315c0.911-28.834,4.602-56.605,10.828-82.201c27.198,3.4,56.366,5.468,86.487,6.06V240.984z
					M240.984,134.808c-27.146-0.547-53.403-2.317-77.958-5.205c4.591-13.292,9.941-25.768,16.022-37.215
					c17.551-33.032,39.128-53.86,61.936-60.249V134.808z M450.717,141.18c17.874,30.193,28.427,64.199,30.749,99.804h-83.088
					c-0.889-29.844-4.584-58.749-10.85-85.647C410.661,151.601,431.984,146.848,450.717,141.18z M415.783,96.216
					c6.029,6.029,11.661,12.349,16.914,18.91c-16.073,4.389-33.972,8.114-53.204,11.112c-5.548-17.208-12.243-33.305-20.02-47.941
					c-6.579-12.382-13.758-23.391-21.43-32.969C366.845,56.535,393.273,73.705,415.783,96.216z M271.016,271.016h97.259
					c-1.004,28.268-4.686,55.49-10.81,80.612c-27.194-3.381-56.349-5.43-86.449-6.006V271.016z M271.016,240.984v-76.041
					c30.005-0.394,59.257-2.261,86.656-5.464c6.125,25.403,9.756,52.932,10.659,81.505H271.016z M271.014,32.139h0.001
					c22.808,6.389,44.384,27.217,61.936,60.249c6.178,11.627,11.601,24.318,16.24,37.848c-24.763,2.712-51.108,4.309-78.177,4.674
					V32.139z M271.016,478.115V375.657c27.12,0.532,53.357,2.286,77.903,5.156c-4.579,13.232-9.911,25.654-15.967,37.053
					C315.4,450.898,293.824,471.726,271.016,478.115z M415.783,415.784c-23.051,23.051-50.21,40.496-79.821,51.678
					c8.457-10.156,16.34-22.011,23.51-35.504c7.62-14.341,14.198-30.088,19.68-46.906c19.465,3.213,37.473,7.186,53.515,11.859
					C427.424,403.457,421.801,409.765,415.783,415.784z M450.606,371.009c-18.635-5.991-40-11.032-63.326-15.01
					c6.296-26.68,10.048-55.36,11.041-84.983h83.146C479.139,306.694,468.549,340.769,450.606,371.009z"
						fill={color}
					/>
				</g>
			</g>
		</svg>
	)
}
