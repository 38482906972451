import { useState, useEffect } from "react"

export const useOnScreen = (ref: any) => {
	const [isIntersecting, setIntersecting] = useState<any>(false)

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
			threshold: 0.05,
		})

		observer.observe(ref.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	return isIntersecting
}

export const useForceUpdate = () => {
	const [value, setValue] = useState(0)
	return () => setValue(value => ++value)
}
