import { linearGradient, rgba } from "polished"
import styled from "styled-components"

export const StyledControls: any = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 15px;
	z-index: 1000;
	height: 160px;
	pointer-events: none;
	background: ${linearGradient({
			colorStops: [`${rgba("#ecebef", 1)} 0%`, `${rgba("#ecebef", 1)} 50%`, `${rgba("#ecebef", 0)} 80%`],
			toDirection: "0deg",
			fallback: "#ecebef",
		})}
		button {
		pointer-events: all;
	}

	${({ link }: any) =>
		link &&
		`
        color: #0d2031;
		position: absolute;
		left: 43px;
		bottom: 26px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 9px;
		padding: 8px 16px;
    `}
`

export const StyledControlsContainer: any = styled.div`
	max-width: 1000px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 92px 43px 0 43px;

	@media (min-width: 1024px) {
		padding: 45px 43px 0 43px;
	}
`
