import React from "react"
import styled from "styled-components"
import { Next } from "../icons"
import { StyledButton } from "../styled"
import { theme } from "../styled/theme"

const StyledAddInput: any = styled.div`
	width: 100%;
	position: relative;
	margin-top: 8px;

	input {
		border: 1px solid rgba(0, 0, 0, 0.3);
		width: 100%;
		font-size: 18px;
		padding: 10px;
		background: none;
		position: relative;
		border-radius: 8px;
		margin-bottom: 24px;

		&::placeholder {
			text-align: center;
			font-style: italic;
			color: ${theme.colors.blue};
			opacity: 1;
		}
	}

	.add-option__submit {
		position: absolute;
		right: 3px;
		top: 3px;
	}

	${StyledButton} {
		padding: 8px 12px;
		border-radius: 6px;

		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}
`

export const AddOption = ({ selector, handleChange, values, onAdd }: any) => {
	const placeholder = "+ Add new"
	return (
		<StyledAddInput className="add-option">
			<input
				onChange={handleChange}
				type="text"
				name={selector}
				placeholder={placeholder}
				onFocus={e => (e.target.placeholder = "")}
				onBlur={e => (e.target.placeholder = placeholder)}
				value={values[selector] || ""}
			/>
			<div
				className="add-option__submit"
				onClick={() => {
					if (values[selector] && values[selector] !== "") {
						onAdd(values[selector])
						values[selector] = ""
					}
				}}
			>
				<StyledButton type="button" className={values[selector] && values[selector] !== "" ? "visible" : "hidden"} dark>
					Add
				</StyledButton>
			</div>
		</StyledAddInput>
	)
}
