import React from "react"

import { connect } from "react-redux"
import { FieldArray, withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import { FormLayout } from "./FormLayout"
import { StyledInput, StyledText } from "../styled"
import { OptionCard, StyledOptionCards } from "."

const Markup = ({ handleSubmit, gotoPrevStep, expertiseCollection, values, isValid, editPrevious }: any) => (
	<FormLayout width={880} title="Which of these areas does your practice primarily support?" onSubmit={handleSubmit}>
		<StyledText small uppercase right marginBottom="10px">
			Choose up to 5 areas
		</StyledText>
		<FieldArray
			name="expertises"
			render={arrayHelpers => (
				<StyledOptionCards>
					{expertiseCollection.map((expertise: any) => (
						<label key={expertise.id}>
							<StyledInput
								hidden
								className="category-option__input"
								name="expertises"
								type="checkbox"
								value={expertise.id}
								checked={values.expertises.filter((x: any) => x.id === expertise.id).length > 0}
								onChange={(event: any) => {
									if (event.target.checked) {
										arrayHelpers.push({
											id: expertise.id,
											label: expertise.label,
											options: [],
										})
									} else {
										const idx = values.expertises.indexOf(
											values.expertises.filter((x: any) => x.id === expertise.id)[0]
										)
										arrayHelpers.remove(idx)
									}
								}}
							/>
							<OptionCard
								isSelected={values.expertises.filter((x: any) => x.id === expertise.id)[0]}
								title={expertise.label}
								description={expertise.description}
								example={expertise.example}
							/>
						</label>
					))}
				</StyledOptionCards>
			)}
		/>
		<RegisterControls
			showPrev={!editPrevious}
			disabled={!(values.expertises.length > 0 && values.expertises.length <= 5 && isValid)}
			onPrevClick={gotoPrevStep}
		/>
	</FormLayout>
)

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		expertises: props.expertises,
	}),
	validate(values) {
		const errors: any = {}
		if (values.expertises.length < 1) {
			errors.expertises = "You must select at least 1 expertise"
		}
		if (values.expertises.length > 5) {
			errors.expertises = "You can select up to 5 expertises"
		}
		return errors
	},
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ expertiseCollection, register, editPrevious }: any) => ({
	expertises: register.expertises,
	expertiseCollection,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 3 }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_FOUR`, payload }),
	}
}

export const RegisterFormStep4 = connect(mapStateToProps, mapDispatchToProps)(Form)
