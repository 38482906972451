import styled from "styled-components"
import { rgba } from "polished"
import { theme } from "./../styled/theme"

export const StyledOption: any = styled.label`
	text-transform: uppercase;
	text-align: center;
	padding: 16px;
	border-radius: 8px;
	background: ${rgba(theme.colors.blue, 0.1)};
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 16px;
	transition: all 0.2s ease-in-out;

	span {
		user-select: none;
	}

	@media (min-width: 1024px) {
		&:hover {
			background: #04aaaa;
			color: #fff;
		}
	}

	${({ selected }: any) =>
		selected &&
		`
		&,
		&:hover {
			background: ${theme.colors.blue};
			color: white;
		}
    `}
`
