import React from "react"

export const Logo = ({ color = "#00157D" }: any) => {
	return (
		<svg width="57" height="14" viewBox="0 0 57 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.35545 8.07809C7.21044 7.82119 7.89444 7.36448 8.37893 6.7365C8.92043 6.05144 9.20543 5.19511 9.20543 4.19606C9.20543 2.88302 8.74943 1.85542 7.86594 1.11327C7.01095 0.428205 5.78546 0.0571289 4.27497 0.0571289H0V12.531H0.712494V8.36353H4.27497C4.78796 8.36353 5.21546 8.33499 5.61446 8.24935L8.66393 12.5025H9.54743L6.35545 8.07809ZM4.30347 7.67847H0.712494V0.742193H4.30347C5.67146 0.742193 6.69745 1.05618 7.43844 1.65561C8.15094 2.25504 8.49293 3.08283 8.49293 4.2246C8.49293 5.33783 8.12244 6.19416 7.43844 6.79359C6.69745 7.36448 5.67146 7.67847 4.30347 7.67847Z"
				fill={color}
			/>
			<path
				d="M13.2239 12.4168C14.2499 10.1047 17.6414 2.82593 18.4393 1.14181C19.2088 2.79739 22.4863 9.96201 23.6548 12.4168L23.7118 12.531H24.5098L18.7243 0.0571289H18.1544L12.3689 12.5025H13.1669L13.2239 12.4168Z"
				fill={color}
			/>
			<path d="M29.4688 0.0571289H28.7563V12.531H29.4688V0.0571289Z" fill={color} />
			<path
				d="M42.9209 10.9896C43.2344 10.5043 43.4054 9.96197 43.4054 9.36254C43.4054 8.62039 43.2059 8.02096 42.8354 7.59279C42.4649 7.16463 42.0089 6.85064 41.4674 6.62229C40.9544 6.42248 40.2419 6.19412 39.3584 5.99431C38.5319 5.7945 37.8764 5.59469 37.3634 5.42342C36.9074 5.25216 36.5084 4.99526 36.1949 4.62418C35.8814 4.28165 35.7389 3.82494 35.7389 3.25405C35.7389 2.5119 36.0239 1.91247 36.6224 1.42722C37.2209 0.941963 38.1044 0.685064 39.2729 0.685064C39.8144 0.685064 40.3844 0.770697 40.9259 0.941963C41.4959 1.11323 42.0089 1.37013 42.4649 1.71266L42.6074 1.82684L43.0064 1.25595L42.8354 1.14177C42.3794 0.799241 41.8094 0.513798 41.1824 0.313988C40.5554 0.114177 39.8999 0 39.2159 0C38.3039 0 37.5059 0.142722 36.8789 0.428165C36.2519 0.713609 35.7674 1.11323 35.4539 1.59848C35.1404 2.08374 34.9979 2.62608 34.9979 3.22551C34.9979 3.96766 35.1974 4.56709 35.5964 5.0238C35.9669 5.45197 36.4514 5.7945 36.9929 5.99431C37.5059 6.19412 38.2469 6.42248 39.1304 6.62229C39.9569 6.8221 40.6124 6.99336 41.0969 7.19317C41.5529 7.36444 41.9519 7.62134 42.2654 7.96387C42.5789 8.3064 42.7214 8.76311 42.7214 9.334C42.7214 10.0762 42.4364 10.6756 41.8379 11.1323C41.2394 11.6175 40.3274 11.8459 39.1019 11.8459C38.3039 11.8459 37.5629 11.7032 36.8504 11.3892C36.1664 11.1037 35.6249 10.7327 35.2544 10.276L35.1404 10.1332L34.6274 10.5899L34.7414 10.7327C35.1689 11.2465 35.7959 11.7032 36.5939 12.0172C37.3919 12.3312 38.2469 12.5024 39.1304 12.5024C40.0709 12.5024 40.8689 12.3597 41.4959 12.0743C42.1229 11.8744 42.6074 11.4748 42.9209 10.9896Z"
				fill={color}
			/>
			<path
				d="M49.305 12.3726V7.09187H55.974V6.40681H49.305V1.29737H56.7435V0.612305H48.5925V13.0576H57V12.3726H49.305Z"
				fill={color}
			/>
		</svg>
	)
}
