import React from "react"

export const Edit = ({ color = "#00157D" }: any) => {
	return (
		<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.3"
				d="M35.8413 23.9211C35.8413 30.4609 30.4604 35.8418 23.9206 35.8418V35.8418C17.3808 35.8418 11.9998 30.4609 11.9998 23.9211V23.9211C11.9998 17.3812 17.3808 12.0003 23.9206 12.0003V12.0003C30.4604 12.0003 35.8413 17.3812 35.8413 23.9211V23.9211Z"
				fill="#00157D"
			/>
			<path
				d="M26.4614 19.1394L28.8414 20.9658L21.1222 31.0247L18.2773 32.4489L18.7422 29.1983L26.4614 19.1394Z"
				fill="#D4D6E4"
			/>
			<path d="M28.8721 16.0002L31.252 17.8266L29.464 20.1566L27.0841 18.3302L28.8721 16.0002Z" fill="#D4D6E4" />
		</svg>
	)
}
