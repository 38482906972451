import { rgba } from "polished"
import styled from "styled-components"

export const StyledButton: any = styled.button`
	padding: 8px 48px;
	border-radius: 24px;
	border: 1px solid black;
	text-transform: uppercase;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.05em;
	color: #0d2031;
	background: transparent;
	transition: all 0.3s ease-in-out;

	@media (min-width: 1024px) {
		&:hover {
			&,
			a {
				color: #fff;
			}
			background: #04aaaa;
			border-color: #04aaaa;
		}
	}

	${({ unstyled }: any) =>
		unstyled &&
		`
        padding: 0;
        margin: 0;
        background: none;
        outline: 0;
		border: none;
		
		@media(min-width: 1024px) {

		&:hover {
			background: transparent;
			border-color: transparent;
		}
		}
    `}

	${({ disabled }: any) =>
		disabled &&
		`
        opacity: 0.3;
        pointer-events: none;
    `}
    
    ${({ filled }: any) =>
		filled &&
		`
        background: #ffffff10;
    `}
	
	${({ shadow }: any) =>
		shadow &&
		`
		box-shadow: 0 5px 5px 0 ${rgba("#000", 0.25)};
		background: #fff;
    `}
	
	${({ noBorder }: any) =>
		noBorder &&
		`
        border: none;
    `}
	
	${({ centered }: any) =>
		centered &&
		`
		display: flex;
        margin-right: auto;
        margin-left: auto;
	`}
	
	${({ dark }: any) =>
		dark &&
		`
		background: #0D2031;
		&,
		a {
			color: #fff;
		}
    `}
`
