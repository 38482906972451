/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Fragment, useEffect, useRef, useState } from "react"

import Header from "./Header"
import "./Layout.css"
import Footer from "./Footer"
import { Logo, Close } from "./icons"
import {
	RegisterFormStep1,
	RegisterFormStep2,
	RegisterFormStep3,
	RegisterFormStep4,
	RegisterFormStep5,
	RegisterFormStep6,
	RegisterFormStep7,
	RegisterFormStep8,
	RegisterFormStep9,
	RegisterFormStep10,
} from "./registerForm"
import {
	StyledModal,
	StyledContainer,
	StyledModalHead,
	StyledModalLogo,
	StyledModalClose,
	StyledButton,
} from "./styled"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { RegisterFormStep0 } from "./registerForm/Step0"

const RegisterModal = ({ showRegisterForm, registrationStep, registrationSubStep, toggleRegisterForm }: any) => {
	const [formScroll, setFormScroll] = useState(0)
	const elementRef = useRef<any>()

	const onScroll = () => {
		setFormScroll(elementRef.current.scrollTop)
	}
	useEffect(() => {
		if (formScroll !== 0 && elementRef.current.scrollTop) {
			elementRef.current.scrollTop = 0
		}
	}, [registrationStep, registrationSubStep])

	return (
		<StyledModal ref={elementRef} onScroll={onScroll} showRegisterForm={showRegisterForm}>
			<StyledContainer>
				{registrationStep !== 10 && (
					<StyledModalHead showRegisterForm={showRegisterForm}>
						<StyledModalLogo>
							<Logo />
						</StyledModalLogo>
						<StyledModalClose>
							<StyledButton unstyled onClick={toggleRegisterForm}>
								<Close />
							</StyledButton>
						</StyledModalClose>
					</StyledModalHead>
				)}
				{registrationStep === 0 && <RegisterFormStep0 showRegisterForm={showRegisterForm} />}
				{registrationStep === 1 && <RegisterFormStep1 />}
				{registrationStep === 2 && <RegisterFormStep2 />}
				{registrationStep === 3 && <RegisterFormStep3 />}
				{registrationStep === 4 && <RegisterFormStep4 />}
				{registrationStep === 5 && <RegisterFormStep5 />}
				{registrationStep === 6 && <RegisterFormStep6 />}
				{registrationStep === 7 && <RegisterFormStep7 />}
				{registrationStep === 8 && <RegisterFormStep8 />}
				{registrationStep === 9 && <RegisterFormStep9 />}
				{registrationStep === 10 && <RegisterFormStep10 />}
			</StyledContainer>
		</StyledModal>
	)
}

const Layout = ({
	onClick,
	showRegisterForm,
	toggleRegisterForm,
	registrationStep,
	registrationSubStep,
	children,
}: any) => (
	<Fragment>
		<Helmet>
			<html className={showRegisterForm ? " overflow--hidden" : ""} />
		</Helmet>

		<RegisterModal
			showRegisterForm={showRegisterForm}
			toggleRegisterForm={toggleRegisterForm}
			registrationStep={registrationStep}
			registrationSubStep={registrationSubStep}
		/>
		<Header onClick={onClick} />
		<main>{children}</main>
		<Footer />
	</Fragment>
)
const mapStateToProps = ({ showRegisterForm, registrationStep, registrationSubStep }: any) => {
	return { showRegisterForm, registrationStep, registrationSubStep }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		toggleRegisterForm: () => dispatch({ type: `TOGGLE_REGISTER_FORM` }),
	}
}

const ConnectedRegisterPage = connect(mapStateToProps, mapDispatchToProps)(Layout)

export default ConnectedRegisterPage
