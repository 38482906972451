import styled from "styled-components"

export const StyledBlock: any = styled.div`
	padding-top: 70px;
	padding-bottom: 50px;
	color: #00157d;

	@media (min-width: 1300px) {
		padding-top: 250px;
		padding-bottom: 170px;
	}

	${({ stage }: any) =>
		stage &&
		`
		padding-top: 18vh;
		padding-bottom: 18vh;
		background: none;
		color: #fff;

		@media(min-width: 1300px) {
			padding-bottom: 180px;
			padding-top: 200px;
		}
	`}

	${({ subPage }: any) =>
		subPage &&
		`
		@media(min-width: 1024px) {
			padding-top: 180px;
		}
	`}

	${({ grey }: any) =>
		grey &&
		`
		background: #ECEBEE;
	`}
`
