import React from "react"
import { StyledInput, StyledInputError, StyledInputGroup } from "../styled"

export const InputGroup = ({
	type = "text",
	name,
	label,
	handleChange,
	handleBlur,
	value,
	error,
	touched,
	required,
}: any) => {
	return (
		<StyledInputGroup>
			<StyledInput
				placeholder={label}
				type={type}
				onChange={handleChange}
				onBlur={handleBlur}
				value={value}
				name={name}
			/>
			{error && touched && <StyledInputError className="error">{error}</StyledInputError>}
		</StyledInputGroup>
	)
}
