import { rgba } from "polished"
import React, { Fragment } from "react"

import styled from "styled-components"
import { Edit, Email, Facebook, Instagram, Web, Youtube } from "../icons"
import { theme } from "../styled/theme"
import { FormLayout } from "./FormLayout"
import { RegisterControls } from "./RegisterControls"
import { v4 as uuid } from "uuid"

import {
	IconSensory,
	IconPsychic,
	IconMystic,
	IconMeditation,
	IconHealth,
	IconGuidance,
	IconEnergy,
	IconBodywork,
	IconActivity,
} from "../icons"
import { StyledText } from "../styled"
import { connect } from "react-redux"

const icons: any = {
	IconSensory,
	IconPsychic,
	IconMystic,
	IconMeditation,
	IconHealth,
	IconGuidance,
	IconEnergy,
	IconBodywork,
	IconActivity,
}

const StyledSummaryCard: any = styled.div`
	background: ${rgba(theme.colors.blue, 0.1)};
	border-radius: 8px;
	padding: 16px;
	position: relative;
	margin-bottom: 16px;

	@media (min-width: 1024px) {
		svg {
			&:hover {
				path {
					transition: all 0.3s ease-in-out;
					opacity: 1;
				}
			}
		}
	}
`

const StyledSummaryCardTitle: any = styled.h2`
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	text-transform: lowercase;
	font-variant: small-caps;
	letter-spacing: 1px;
`

const StyledSummaryCardEdit: any = styled.span`
	position: absolute;
	top: 0;
	right: 0;
`

const StyledList: any = styled.ul`
	font-size: 13px;
	line-height: 166%;

	${({ unstyled }: any) =>
		unstyled &&
		`
		list-style: none;
		margin: 0;
		padding: 0;
	`}
`

const StyledGrid: any = styled.div`
	display: grid;

	${({ columns }: any) =>
		columns &&
		`
		grid-template-columns: repeat(${columns}, 1fr);
	`}

	${({ columnsLayout }: any) =>
		columnsLayout &&
		`
		grid-template-columns: ${columnsLayout};
	`}

	${({ gridGap }: any) =>
		gridGap &&
		`
		grid-gap: ${gridGap}px;
	`}
	
	${({ marginBottom }: any) =>
		marginBottom &&
		`
		margin-bottom: ${marginBottom}px;
	`}
`

const StyledListItem: any = styled.li`
	margin: 0;
	padding: 0;
	font-weight: 400;
	display: flex;
	align-items: center;

	svg {
		height: 16px;
		width: 16px;
		margin-right: 8px;
	}
`

const StyledListCategories: any = styled(StyledList).attrs(props => (props.unstyled = true))`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;
	justify-content: center;
`
const StyledListCategoriesItem: any = styled.li`
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;

	svg {
		height: 36px;
		width: 36x;
	}

	${StyledText} {
		margin-top: 8px;
	}
`

const StyledExpertiseOption: any = styled.div`
	text-align: center;
	font-size: 13px;
	background: #ecebef;
	border-radius: 4px;
	padding: 3px;
`

const StyledBusiness: any = styled.div`
	font-size: 13px;
`

const StyledOnlineOfflineOption: any = styled.div`
	display: flex;
	align-items: center;

	${StyledText} {
		margin-right: 4px;
	}
`

const StyledSpecialOffers: any = styled.div`
	margin-top: 16px;
`

const SummaryCard = ({ title, onClick, children }: any) => (
	<StyledSummaryCard>
		<StyledSummaryCardTitle>{title}</StyledSummaryCardTitle>
		<StyledSummaryCardEdit onClick={onClick}>
			<Edit />
		</StyledSummaryCardEdit>
		{children}
	</StyledSummaryCard>
)

const Markup = ({ categoryCollection, submit, gotoPrevStep, register, gotoStep, setEditPrevious }: any) => {
	const getIcon = (id: string) => categoryCollection.filter((category: any) => category.id === id)[0].icon
	const handleEditStep = (step: number) => {
		setEditPrevious(), gotoStep(step)
	}
	const business = [
		{
			label: "Sessions",
			online: register.services.sessions.online,
			offline: register.services.sessions.offline,
			range: register.services.sessions.range,
		},
		{
			label: "Courses",
			online: register.services.courses.online,
			offline: register.services.courses.offline,
			range: register.services.courses.range,
		},
		{
			label: "Seminars",
			online: register.services.seminars.online,
			offline: register.services.seminars.offline,
			range: register.services.seminars.range,
		},
		{
			label: "Events",
			online: register.services.events.online,
			offline: register.services.events.offline,
			range: register.services.events.range,
		},
		{
			label: "Retreats",
			online: register.services.retreats.online,
			offline: register.services.retreats.offline,
			range: register.services.retreats.range,
		},
	]
	return (
		<FormLayout title="Please review your information and submit your application.">
			<div style={{ marginTop: 213, maxWidth: 328, marginLeft: "auto", marginRight: "auto", paddingBottom: 150 }}>
				<SummaryCard title="Profile" onClick={() => handleEditStep(1)}>
					<StyledList unstyled>
						<StyledListItem>
							{register.name}, {register.age}
						</StyledListItem>
						<StyledListItem>{register.city}</StyledListItem>
						<StyledListItem>
							{register.role} / {register.experience} Years
						</StyledListItem>
					</StyledList>
				</SummaryCard>
				<SummaryCard title="Categories" onClick={() => handleEditStep(2)}>
					<StyledListCategories>
						{register.categories.map((category: any) => (
							<StyledListCategoriesItem key={uuid()}>
								{React.createElement(icons[getIcon(category.id)])}
								<StyledText uppercase small>
									{category.label}
								</StyledText>
							</StyledListCategoriesItem>
						))}
					</StyledListCategories>
				</SummaryCard>
				<SummaryCard title="Expertise" onClick={() => handleEditStep(4)}>
					<StyledGrid columns={2} gridGap={8}>
						{register.expertises.map((expertise: any) => (
							<StyledExpertiseOption key={uuid()}>{expertise.label}</StyledExpertiseOption>
						))}
					</StyledGrid>
				</SummaryCard>
				<SummaryCard title="Contact" onClick={() => handleEditStep(6)}>
					<StyledList unstyled>
						{register.email && (
							<StyledListItem>
								<Email /> {register.email}
							</StyledListItem>
						)}
						{register.website && (
							<StyledListItem>
								<Web />
								{register.website}
							</StyledListItem>
						)}
						{register.instagram && (
							<StyledListItem>
								<Instagram />
								{register.instagram}
							</StyledListItem>
						)}
						{register.facebook && (
							<StyledListItem>
								<Facebook />
								{register.facebook}
							</StyledListItem>
						)}
						{register.youtube && (
							<StyledListItem>
								<Youtube />
								{register.youtube}
							</StyledListItem>
						)}
					</StyledList>
				</SummaryCard>
				<SummaryCard title="Offering" onClick={() => handleEditStep(7)}>
					<StyledGrid columnsLayout="2fr 1fr 1fr 2fr" marginBottom={8}>
						<span></span>
						<StyledText tiny uppercase>
							Online
						</StyledText>
						<StyledText tiny uppercase>
							Offline
						</StyledText>
						<StyledText tiny uppercase>
							Price Range
						</StyledText>
					</StyledGrid>
					<StyledGrid columnsLayout="2fr 1fr 1fr 2fr">
						{business.map(
							(businessItem: any) =>
								(!!businessItem.online || businessItem.offline) && (
									<Fragment key={uuid()}>
										<StyledText small>{businessItem.label}</StyledText>
										<StyledOnlineOfflineOption>
											<StyledText small>{!!businessItem.online ? "Yes" : "No"}</StyledText>
										</StyledOnlineOfflineOption>
										<StyledOnlineOfflineOption>
											<StyledText small>{!!businessItem.offline ? "Yes" : "No"}</StyledText>
										</StyledOnlineOfflineOption>
										<StyledOnlineOfflineOption>
											<StyledText small>{!!businessItem.range ? `€ ${businessItem.range}` : "-"}</StyledText>
										</StyledOnlineOfflineOption>
									</Fragment>
								)
						)}
						{!!register.services.specialOffers && (
							<StyledSpecialOffers>
								<StyledText small italic>
									Special offers
								</StyledText>
								<StyledText small>{register.services.specialOffers}</StyledText>
							</StyledSpecialOffers>
						)}
					</StyledGrid>
				</SummaryCard>
			</div>
			<RegisterControls onPrevClick={gotoPrevStep} onNextClick={submit} nextLabel="Submit" />
		</FormLayout>
	)
}

const mapStateToProps = ({ register, categoryCollection }: any) => ({
	register,
	categoryCollection,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoStep: (payload: any) => dispatch({ type: `GOTO_REGISTER_STEP`, payload }),
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 7 }),
		setEditPrevious: () => dispatch({ type: `SET_EDIT_PREVIOUS`, payload: true }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_EIGHT`, payload }),
	}
}

export const RegisterFormStep8 = connect(mapStateToProps, mapDispatchToProps)(Markup)
