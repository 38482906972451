import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { FieldArray, withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import { FormLayout } from "./FormLayout"
import styled from "styled-components"
import { rgba } from "polished"
import { StyledOption, StyledOptionWrapper, StyledInput } from "../styled"
import { useForceUpdate } from "../../util/hooks"

const StyledExpertisesHeader = styled.div`
	display: flex;
	justify-content: space-between;
`

const StyledExpertisesTitle = styled.h2`
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
`

const StyledExpertisesCount = styled.span`
	font-size: 11px;
	letter-spacing: -1px;
`

const StyledExpertisesStep: any = styled.div`
	@media (max-width: 1023px) {
		max-width: 400px;
		margin: 0 auto;
	}
	${({ selected }: any) =>
		!selected &&
		`
		display: none;
	`}
`

const StyledExpertisesOptionRating: any = styled.div`
	display: flex;
	width: 100%;
`

const StyledExpertisesOptionRatingItemDot: any = styled.span`
	background: ${rgba("#00157d", 0.3)};
	border-radius: 50%;
	transition: all 0.2s ease-in-out;

	${({ selected }: any) =>
		selected &&
		`
		background: #00157d;
	`}

	${({ size }: any) =>
		size === 0 &&
		`
		height: 5px;
		width: 5px;
	`}
	${({ size }: any) =>
		size === 1 &&
		`
		height: 10px;
		width: 10px;
	`}
	${({ size }: any) =>
		size === 2 &&
		`
		height: 15px;
		width: 15px;
	`}
`

const StyledExpertisesOptionRatingItem: any = styled.label`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;

	@media (min-width: 1024px) {
		&:hover {
			${StyledExpertisesOptionRatingItemDot} {
				background: #04aaaa;
			}

			${({ current }: any) =>
				current &&
				`
				${StyledExpertisesOptionRatingItemDot} {
					background: #00157d !important;
				}
			`}
		}
	}
`

const Markup = ({
	handleSubmit,
	gotoPrevStep,
	gotoSubStep,
	expertises,
	values,
	expertiseCollection,
	editPrevious,
}: any) => {
	const [current, setCurrent] = useState(1)
	const [currentValid, setCurrentValid] = useState(false)
	const [ratingChange, setRatingChange] = useState(false)
	const forceUpdate = useForceUpdate()

	const undefinedOptions = values.expertises.map(
		(expertise: any) => expertise.options.filter((option: any) => option.rating === undefined).length
	)

	useEffect(() => {
		const hasSelected = values.expertises[current - 1] && values.expertises[current - 1].options.length > 0
		const hasRating = hasSelected && undefinedOptions[current - 1] === 0
		setCurrentValid(hasRating)
	}, [current, values, ratingChange])

	const handlePrevStep = () => {
		if (current > 1) {
			setCurrent(current - 1)
			gotoSubStep(current - 1)
		} else {
			gotoPrevStep()
		}
	}

	const handleNextStep = () => {
		if (undefinedOptions[current - 1] > 0) {
			return false
		}
		if (current - 1 === expertises.length) {
			handleSubmit()
		} else {
			setCurrent(current + 1)
			gotoSubStep(current + 1)
		}
	}

	const getExpertiseOptions = (index: number) =>
		expertiseCollection.filter((cat: any) => cat.id === expertises[index].id)[0].options

	const findExpertiseOptionId = (index: number, expertiseId: any) =>
		values.expertises[index].options.filter((x: any) => x.id === expertiseId)[0]

	const getOptionRating = (values: any, index: number, expertiseOption: any) => {
		const currentOption = values.expertises[index].options
		return currentOption[currentOption.indexOf(findExpertiseOptionId(index, expertiseOption.id))]
	}

	const setRating = (values: any, index: number, expertiseOption: any, rating: number) => {
		const optionData = getOptionRating(values, index, expertiseOption)
		optionData.rating = rating
		setRatingChange(!ratingChange)
		return getRating(values, index, expertiseOption, rating)
	}

	const getRating = (values: any, index: number, expertiseOption: any, rating: number) => {
		const optionData = getOptionRating(values, index, expertiseOption)
		return optionData.rating >= rating
	}
	const getCurrent = (values: any, index: number, expertiseOption: any, rating: number) => {
		const optionData = getOptionRating(values, index, expertiseOption)
		return optionData.rating === rating
	}

	return (
		<FormLayout
			width={280}
			title="Select all relevant topics and rate your expertise from 1 to 3."
			onSubmit={handleSubmit}
		>
			{expertises.map((expertise: any, index: number) => (
				<StyledExpertisesStep key={index} selected={index === current - 1}>
					<FieldArray
						name={`expertises[${index}].options`}
						render={arrayHelpers => (
							<div>
								<StyledExpertisesHeader>
									<StyledExpertisesTitle>{expertise.label}</StyledExpertisesTitle>
									<StyledExpertisesCount>
										{index + 1} / {expertises.length}
									</StyledExpertisesCount>
								</StyledExpertisesHeader>

								{getExpertiseOptions(index).map((expertiseOption: any, optionIndex: number) => (
									<StyledOptionWrapper key={expertiseOption.id}>
										<StyledOption selected={!!findExpertiseOptionId(index, expertiseOption.id)}>
											<StyledInput
												hidden
												name={`expertises[${index}].options`}
												type="checkbox"
												checked={!!findExpertiseOptionId(index, expertiseOption.id)}
												onChange={(event: any) => {
													if (event.target.checked) {
														arrayHelpers.push({
															id: expertiseOption.id,
															label: expertiseOption.label,
															rating: undefined,
														})
													} else {
														const idx = values.expertises[index].options.indexOf(
															findExpertiseOptionId(index, expertiseOption.id)
														)
														arrayHelpers.remove(idx)
													}
												}}
											/>
											<span>{expertiseOption.label}</span>
										</StyledOption>
										{!!findExpertiseOptionId(index, expertiseOption.id) && (
											<StyledExpertisesOptionRating>
												{[0, 1, 2].map((rating: number) => (
													<StyledExpertisesOptionRatingItem
														current={getCurrent(values, index, expertiseOption, rating)}
														key={`expertises.${index}.options.${rating}`}
													>
														<StyledInput
															hidden
															name={`expertises[${index}].options[${optionIndex}]`}
															type="radio"
															onChange={() => {
																setRating(values, index, expertiseOption, rating)
																forceUpdate()
															}}
														/>
														<StyledExpertisesOptionRatingItemDot
															size={rating}
															selected={!!getRating(values, index, expertiseOption, rating)}
														/>
													</StyledExpertisesOptionRatingItem>
												))}
											</StyledExpertisesOptionRating>
										)}
									</StyledOptionWrapper>
								))}
							</div>
						)}
					/>
				</StyledExpertisesStep>
			))}
			<RegisterControls
				showPrev={!editPrevious}
				onPrevClick={handlePrevStep}
				onNextClick={handleNextStep}
				submit={current - 1 === expertises.length}
				disabled={!currentValid}
			/>
		</FormLayout>
	)
}

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		expertises: props.expertises,
	}),
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ expertiseCollection, register, editPrevious }: any) => ({
	expertises: register.expertises,
	expertiseCollection: expertiseCollection,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 4 }),
		gotoSubStep: (subStep: number) => dispatch({ type: "SET_REGISTER_SUB_STEP", payload: subStep }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_FIVE`, payload }),
	}
}

export const RegisterFormStep5 = connect(mapStateToProps, mapDispatchToProps)(Form)
