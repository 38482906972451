import React from "react"
import { connect } from "react-redux"
import { withFormik } from "formik"
import { RegisterControls } from "./RegisterControls"
import { InputGroup } from "./InputGroup"
import { FormLayout } from "./FormLayout"
import { hasValues } from "../../util/functions"

const Markup = ({
	gotoPrevStep,
	values,
	touched,
	errors,
	handleChange,
	handleBlur,
	handleSubmit,
	isValid,
	editPrevious,
}: any) => (
	<FormLayout width={328} title="Please enter your profile information." onSubmit={handleSubmit}>
		<InputGroup
			name="name"
			label="Name"
			handleChange={handleChange}
			handleBlur={handleBlur}
			value={values.name}
			error={errors.name}
			touched={touched.name}
			required={true}
		/>
		<InputGroup
			name="age"
			label="Age"
			type="number"
			handleChange={handleChange}
			handleBlur={handleBlur}
			value={values.age}
			error={errors.age}
			touched={touched.age}
		/>
		<InputGroup
			name="city"
			label="City"
			handleChange={handleChange}
			handleBlur={handleBlur}
			value={values.city}
			error={errors.city}
			touched={touched.city}
			required={true}
		/>
		<InputGroup
			name="role"
			label="Title/Role (e.g. Astrologer)"
			handleChange={handleChange}
			handleBlur={handleBlur}
			value={values.role}
			error={errors.role}
			touched={touched.role}
			required={true}
		/>
		<InputGroup
			name="experience"
			label="Years of Experience"
			type="number"
			handleChange={handleChange}
			handleBlur={handleBlur}
			value={values.experience}
			error={errors.experience}
			touched={touched.experience}
		/>
		<RegisterControls showPrev={!editPrevious} disabled={!(hasValues(values) && isValid)} onPrevClick={gotoPrevStep} />
	</FormLayout>
)

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		name: props.name,
		age: props.age,
		city: props.city,
		role: props.role,
		experience: props.experience,
	}),
	validate(values) {
		const errors: any = {}

		if (!values.name) {
			errors.name = "Required"
		}
		if (!values.age) {
			errors.age = "Required"
		}
		if (!values.city) {
			errors.city = "Required"
		}
		if (!values.role) {
			errors.role = "Required"
		}
		if (!values.experience) {
			errors.experience = "Required"
		}
		return errors
	},
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ register, editPrevious }: any) => ({
	name: register.name,
	age: register.age,
	city: register.city,
	role: register.role,
	experience: register.experience,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 0 }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_ONE`, payload }),
	}
}

export const RegisterFormStep1 = connect(mapStateToProps, mapDispatchToProps)(Form)
