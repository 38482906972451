import React from "react"
import styled from "styled-components"
import { StyledButton, StyledControlsContainer, StyledControls } from "../styled"

const StyledPrevControl: any = styled(StyledButton)`
	opacity: 1;
	padding: 4px 16px;
	margin: 0;
	background: none !important;
	color: #0d2031 !important;
	outline: 0;
	border: none;
	font-size: 9px;
	transition: all 0.3s ease-in-out;

	@media (min-width: 1024px) {
		&:hover {
			color: #04aaaa !important;
		}
	}
`

export const RegisterControls = ({
	onPrevClick,
	onNextClick,
	showPrev = true,
	showNext = true,
	disabled = false,
	submit = true,
	prevLabel = "Back",
	nextLabel = "Next",
}: any) => {
	return (
		<StyledControls>
			<StyledControlsContainer>
				{showPrev ? (
					<StyledPrevControl type="button" onClick={() => onPrevClick && onPrevClick()}>
						{prevLabel}
					</StyledPrevControl>
				) : (
					<span />
				)}
				{showNext && (
					<StyledButton
						dark
						onClick={() => onNextClick && onNextClick()}
						type={submit ? "submit" : "button"}
						disabled={disabled}
					>
						{nextLabel}
					</StyledButton>
				)}
			</StyledControlsContainer>
		</StyledControls>
	)
}
