import React from "react"

export const IconGuidance = ({ color = "#00157D" }: any) => {
	return (
		<svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.7446 0.160645C17.4235 0.160645 13.111 4.25235 13.111 9.22786C13.111 12.4685 14.9542 15.48 17.9451 17.1167C18.0495 17.1821 18.1538 17.2149 18.2582 17.2803V20.6846C18.2582 22.8778 20.171 24.6454 22.4663 24.6454H23.2315C25.5616 24.6454 27.4396 22.8451 27.4396 20.6846V17.1821C27.544 17.1167 27.6831 17.0512 27.7874 17.0185C30.674 15.3491 32.4129 12.4358 32.4129 9.22786C32.4129 4.25235 28.1004 0.160645 22.7446 0.160645ZM27.0571 15.8728C25.9789 16.4947 24.7269 16.8548 23.4749 16.9203V12.4358H25.6312V11.0937H19.8927V12.4358H22.049V16.9203C20.8317 16.8548 19.6841 16.5275 18.6407 15.971C16.1019 14.5962 14.5021 12.0102 14.5021 9.22786C14.5021 4.97249 18.2234 1.46999 22.7446 1.46999C27.2657 1.46999 30.987 4.93976 30.987 9.22786C31.0218 11.9775 29.5263 14.4325 27.0571 15.8728Z"
				fill={color}
			/>
			<path
				d="M38.8474 25.5949C37.7692 24.5802 36.0303 24.5802 34.9174 25.5949L30.4658 29.7848C29.9093 29.7194 29.3181 29.7194 28.6573 29.7521C25.3882 30.0467 24.0666 29.6539 23.8231 29.5557C23.1971 28.9992 19.4759 25.8568 15.998 25.6604C12.242 25.464 9.25103 27.6244 9.04236 27.7553L0 36.2661L5.94709 41.8636L12.7289 36.1352C14.3634 36.6262 20.4844 38.3938 24.31 38.0337L25.1795 37.9682C27.8226 37.7391 29.4572 37.6082 33.1437 34.5639C33.2481 34.4985 33.3524 34.433 33.422 34.3348C34.8479 33.1564 36.552 31.5197 38.7083 29.3593L38.9169 29.1629C39.9951 28.1809 39.9603 26.6424 38.8474 25.5949ZM37.7345 28.4428C36.2738 29.9158 35.0218 31.1269 33.9436 32.1089C33.8045 31.7161 33.5611 31.2906 33.1437 30.8978C32.8307 30.6032 32.4829 30.374 32.0656 30.2104L35.926 26.5442C36.4477 26.0532 37.3171 26.0532 37.8388 26.5442C38.2909 27.0025 38.4996 27.6899 37.9431 28.2463L37.7345 28.4428ZM25.2143 34.7931C27.0575 35.1532 28.5878 35.3168 29.8398 35.3168C28.0313 36.4298 26.8141 36.4952 25.0752 36.6589L24.2057 36.7244C20.1366 37.0844 12.868 34.7603 12.7984 34.7603L12.4159 34.6294L6.01665 40.0632L1.98236 36.2661L9.98137 28.7374C10.0162 28.7046 12.7289 26.8715 15.9285 26.9697C19.1628 27.1334 22.9189 30.5704 22.9537 30.6032L23.0928 30.7014C23.1971 30.7341 23.7188 30.996 25.1099 31.1269C25.9794 31.1924 27.1619 31.2251 28.7964 31.0614C30.3267 30.9305 31.4744 31.1924 32.1352 31.7816C32.6221 32.2071 32.6916 32.7309 32.6568 32.8618C32.6221 33.0582 32.5873 33.2873 32.309 33.4837C31.7874 33.8765 30.1876 34.3675 25.3882 33.4183L25.2838 33.3855L25.1447 33.4183C25.0752 33.451 20.1019 34.1057 17.3196 32.0435C17.1457 31.9453 17.0066 31.8471 16.8327 31.6834L15.8241 32.6327C18.7803 35.415 24.2753 34.8913 25.2143 34.7931Z"
				fill={color}
			/>
		</svg>
	)
}
