import styled from "styled-components"

export const StyledH1: any = styled.h1`
	font-size: 30px;
	text-transform: uppercase;
	font-weight: 400;
	color: #fff;
`

export const StyledH2: any = styled.h2`
	font-size: 30px;
	line-height: 120%;
	text-transform: uppercase;
	font-weight: 400;

	@media (min-width: 1024px) {
		font-size: 60px;
	}
`

export const StyledH3: any = styled.h3`
	font-size: 23px;
	line-height: 143%;
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 19px;
	margin-top: 0;
`
