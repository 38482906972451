import React from "react"
import { connect } from "react-redux"
import { FieldArray, withFormik } from "formik"
import { FormLayout } from "./FormLayout"
import { StyledInput } from "../styled"
import styled from "styled-components"
import { OptionCard, RegisterControls, StyledOptionCards } from "."

const StyledCategoryCards = styled.div`
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 16px;
	}
`

const Markup = ({ name, values, handleSubmit, gotoPrevStep, categoryCollection, isValid, editPrevious }: any) => (
	<FormLayout
		width={880}
		title={`Hi ${name.split(" ")[0]}, select all categories that describe your field of work.`}
		onSubmit={handleSubmit}
	>
		<FieldArray
			name="categories"
			render={arrayHelpers => (
				<StyledOptionCards>
					{categoryCollection.map((category: any) => (
						<label key={category.id}>
							<StyledInput
								hidden
								name="categories"
								type="checkbox"
								value={category.id}
								checked={values.categories.filter((x: any) => x.id === category.id).length > 0}
								onChange={(event: any) => {
									if (event.target.checked) {
										arrayHelpers.push({
											id: category.id,
											label: category.label,
											options: [],
										})
									} else {
										const idx = values.categories.indexOf(values.categories.filter((x: any) => x.id === category.id)[0])
										arrayHelpers.remove(idx)
									}
								}}
							/>
							<OptionCard
								isSelected={values.categories.filter((x: any) => x.id === category.id)[0]}
								title={category.label}
								description={category.description}
								example={category.example}
								icon={category.icon}
							/>
						</label>
					))}
				</StyledOptionCards>
			)}
		/>
		<RegisterControls
			showPrev={!editPrevious}
			disabled={!(values.categories.length > 0 && isValid)}
			onPrevClick={gotoPrevStep}
		/>
	</FormLayout>
)

const Form = withFormik({
	mapPropsToValues: (props: any) => ({
		categories: props.categories,
	}),
	validate(values) {
		const errors: any = {}

		if (values.categories.length < 1) {
			errors.name = "You must select at least 1 category"
		}
		return errors
	},
	handleSubmit(values, { props, setSubmitting }: any) {
		setSubmitting(false)
		props.submit(values)
	},
})(Markup)

const mapStateToProps = ({ categoryCollection, register, editPrevious }: any) => ({
	categories: register.categories,
	categoryCollection: categoryCollection,
	name: register.name,
	editPrevious,
})

const mapDispatchToProps = (dispatch: any) => {
	return {
		gotoPrevStep: () => dispatch({ type: `GOTO_REGISTER_STEP`, payload: 1 }),
		submit: (payload: any) => dispatch({ type: `SUBMIT_REGISTER_STEP_TWO`, payload }),
	}
}

export const RegisterFormStep2 = connect(mapStateToProps, mapDispatchToProps)(Form)
