import styled from "styled-components"

export const StyledText: any = styled.p`
	margin-bottom: 0;

	${({ marginTop }: any) =>
		marginTop &&
		`
		margin-top: ${marginTop};
	`}
	${({ marginBottom }: any) =>
		marginBottom &&
		`
		margin-bottom: ${marginBottom};
	`}
	${({ uppercase }: any) =>
		uppercase &&
		`
		text-transform: uppercase;
	`}
	${({ tiny }: any) =>
		tiny &&
		`
		font-size: 9px;
	`}
	${({ bold }: any) =>
		bold &&
		`
		font-weight: 700;
	`}
	${({ right }: any) =>
		right &&
		`
        text-align: right;
	`}
	${({ small }: any) =>
		small &&
		`
		font-size: 13px;
	`}
	${({ center }: any) =>
		center &&
		`
		text-align: center;
	`}
	${({ italic }: any) =>
		italic &&
		`
		font-style: italic;
	`}
	${({ landing }: any) =>
		landing &&
		`
		line-height: 150%;
		font-size: 18px;
		margin-bottom: 36px;
	`}
`

export const StyledError: any = styled.span`
	color: #ff0716;
	font-size: 13px;
	margin-bottom: 8px;
	display: block;
`
