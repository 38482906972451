import styled from "styled-components"

export const StyledModal: any = styled.div`
	position: fixed;
	top: -1px;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	overflow-y: scroll;
	background: #ecebef;
	padding-bottom: 100px;
	color: #00157d;
	z-index: 20000000000000;
	transition: top 0.3s ease-in-out;

	${({ showRegisterForm }: any) => (showRegisterForm === true ? `top: 0;` : `top: 100vh;`)}
`

export const StyledModalHead: any = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 990000;
	transition: top 0.3s ease-in-out;

	${({ showRegisterForm }: any) => (showRegisterForm === true ? `top: 0;` : `top: 100vh;`)}
`

export const StyledModalLogo: any = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 36px;

	@media (min-width: 1024px) {
		margin-top: 68px;
	}
`

export const StyledModalClose: any = styled.div`
	position: absolute;
	top: 16px;
	right: 16px;

	@media (min-width: 1024px) {
		top: 60px;
		right: 60px;
	}
`
